import React from 'react';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import FeaturedBlocks from '@components/featured-blocks/index';
import Tabs from '@components/tabs/index';
import News from '@components/news';
import DownloadApp from '@components/download-app';

const IndexPage = () => {
  /* useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.hash.includes('news')
    ) {
      setTimeout(() => {
        history.replaceState(null, null, ' ');
      }, 500);
    }
  }, []); */

  return (
    <Layout>
      <SEO title="الصفحة الرئيسية" />
      <FeaturedBlocks />
      <Tabs />
      <News />
      <DownloadApp />
    </Layout>
  );
};

export default IndexPage;
