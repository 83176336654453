import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CURRENCIES } from '@utils/queries';
import { List } from './styles';
import Content from './content';

const FeaturedBlocks = () => {
  const { data } = useQuery(GET_CURRENCIES);

  return (
    <List>
      <Content data={data.currencies.filter(item => item.isFeatured)} />
    </List>
  );
};

export default FeaturedBlocks;
