/* eslint-disable global-require */
import styled, { css } from 'styled-components';
import { defaultTheme } from '@styledComponents/theme';
import { COIN_TYPE, VALUE_STATUS } from './defines';

const { width } = typeof window !== 'undefined' ? window.innerWidth : 1300;

const redBg = '#e91e63';
const blueBg = '#0091ea';
const blackBg = defaultTheme.backgroundColor;
const goldBg = defaultTheme.goldColor;
const boxBlackBg = defaultTheme.lighBlackColor;
const shadowColor = 'rgba(0, 0, 0, 0.1)';

function getBg(props) {
  switch (props.type) {
    case COIN_TYPE.RED:
      return redBg;
    case COIN_TYPE.BLUE:
      return blueBg;
    case COIN_TYPE.GOLD:
      return goldBg;
    default:
      return blackBg;
  }
}

export const Top = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: ${props => getBg(props)};
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin: 0 4px 0 0;
  color: ${props =>
    props.type === COIN_TYPE.GOLD
      ? props.theme.secondaryColor
      : props.theme.textColor};
  font-family: ${props => props.theme.fonts.arabicFont};
`;

export const Symbol = styled.div`
  font-size: 14px;
  margin: 0 0 0 4px;
  line-height: 24px;
  color: ${props =>
    props.type === COIN_TYPE.GOLD
      ? props.theme.darkText2
      : props.theme.lightText};
  font-family: 'Roboto';
`;

export const Value = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 50px;
  margin: 0;
  color: ${props =>
    props.type === COIN_TYPE.GOLD
      ? props.theme.darkText2
      : props.theme.lightText};
  text-align: center;
  font-family: 'Roboto';
  background-color: ${props => getBg(props)};
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  background-color: ${shadowColor};
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const PreValue = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto';
  line-height: 30px;
  margin: 0 0 0 2px;
  color: ${props =>
    props.type === COIN_TYPE.GOLD
      ? props.theme.darkText2
      : props.theme.lightText};
  text-align: left;
`;

export const ChangePercent = styled.div`
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 25px;
  margin: 0 2px 0 0;
  color: ${props => props.theme.textColor};
`;

export const ChangeArrow = styled.img`
  width: 20px;
  height: 30px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-left: 2px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: ${width > 359 ? '90px' : '70px'};
`;

export const BottomDetails = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.textColor};
  padding: 3px 8px;
  margin-top: auto;

  .side {
    display: flex;
    align-items: center;
    ${PreValue} {
      margin-right: 4px;
    }
  }

  ${props => {
    if (props.type === VALUE_STATUS.UP) {
      return css`
        background-color: ${props.theme.upValueColor};
      `;
    } else if (props.type === VALUE_STATUS.DOWN) {
      return css`
        background-color: ${props.theme.downValueColor};
      `;
    } else {
      return css`
        background-color: ${props.theme.stableValueColor};
      `;
    }
  }};
`;

export const Box = styled.div`
  background-color: ${props => getBg(props)};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 232px;
  overflow: hidden;
  width: 262px;

  ${Top} {
    justify-content: center;
  }

  ${Title} {
    color: ${props =>
      props.type === COIN_TYPE.GOLD
        ? props.theme.textDarkGold
        : props.theme.textColor};
    font-size: 21px;
    font-weight: 700;
    text-align: center;
  }

  ${Value} {
    background-color: transparent;
    color: ${props =>
      props.type === COIN_TYPE.GOLD
        ? props.theme.textColorBlack
        : props.theme.textColor};
    font-size: 48px;
    margin: auto 0 0;
    height: 90px;
    line-height: 2;
  }
`;

export const WideBox = styled.div`
  align-items: center;
  background-size: cover;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  padding: 15px;
  position: relative;
  flex-direction: column;
  width: 100%;

  ${props =>
    props.background
      ? css`
          background-image: url('${props.background}');
          background-size: cover;
          background-position: center center;
          & > * {
            position: relative;
            z-index: 1;
          }
          &:before {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
          }
        `
      : css`
          background-color: ${boxBlackBg};
        `};

  @media (min-width: 992px) {
    background-size: contain;
    flex-direction: row;
    justify-content: space-between;
    width: 555px;
    height: 225px;
    align-items: initial;
  }
`;

export const WideContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;

  ${Title} {
    color: ${props => props.theme.textColor};
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    text-shadow: 1px 1px 2px black;
  }

  ${Value} {
    background-color: transparent;
    color: ${props => props.theme.textColor};
    font-size: 48px;
    line-height: 1.4;
    text-shadow: 1px 1px 2px black;
  }

  ${BottomDetails} {
    margin-top: auto;
  }

  @media (min-width: 992px) {
    width: 180px;
    margin: 0 2px 0;
    ${Value} {
      line-height: 2.7;
      height: 132px;
    }
  }
`;

export const Info = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.textColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  ${Title} {
    font-size: 28px;
    text-shadow: 1px 1px 2px black;
    line-height: 1.5;
    text-align: center;
  }
`;

export const FeaturedBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 300px;
  padding: 0 30px;
  margin-bottom: 40px;
  border-left: 2px solid transparent;

  @media (min-width: 724px) {
    border-left: ${props =>
      `2px solid ${
        props.hideBorder ? 'transparent' : props.theme.borderBlockColor
      }`};
  }

  ${Title} {
    color: ${props => props.theme.headingTxtGold};
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  ${Details} {
    flex-direction: row;
    display: grid;
    grid-template-columns: ${props => (props.oneColumn ? 'auto' : 'auto auto')};
  }

  ${ChangePercent} {
    font-weight: 400;
  }
`;

export const FeaturedDetails = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: ${props => (props.oneColumn ? 'auto' : 'auto auto')};
  overflow: hidden;
  margin: 0 2px 20px 0;
`;

export const ArrowIcon = styled.div`
  color: ${props => props.theme.textColor};
  font-size: 18px;
`;

export const FeaturedContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5px;

  ${Title} {
    color: ${props => props.theme.textColor};
    font-size: 16px;
    font-weight: 400;
  }

  ${Value} {
    background-color: transparent;
    color: ${props => props.theme.textColor};
    font-size: 48px;
  }
`;

export const WidTitle = styled(Title)`
  line-height: 18px;
  line-height: 70px;
  text-align: center;
`;

export const WidSymbol = styled(Symbol)`
  line-height: 18px;
  text-align: center;
  margin: 0 10px;
`;

export const WidPreValue = styled(PreValue)`
  line-height: ${width > 359 ? '18px' : '16px'};
  font-size: ${width > 359 ? '16px' : '14px'};
  text-align: center;
  margin: 0 10px;
`;

export const WidChangePercent = styled(ChangePercent)`
  line-height: 18px;
  text-align: center;
  margin: 10px 10px 0;
`;

export const WidChangeArrow = styled(ChangeArrow).attrs({
  resizeMode: 'contain',
})`
  margin-top: 20px;
  margin-right: 4px;
`;

export const WidValue = styled(Value)`
  line-height: 70px;
  font-size: ${width > 359 ? '21px' : '16px'};
  width: ${width > 359 ? '100px' : '90px'};
  background-color: ${boxBlackBg};
  margin-bottom: ${width > 359 ? '0px' : '1px'};
`;

export const LoadingItem = styled.div`
  width: ${props => `${props.width}px` || '100px'};
  height: ${props => `${props.height}px` || '10px'};
  border-radius: ${props => props.borderRadius || 0};
  margin-top: ${props => props.marginTop || '6px'};
  margin-bottom: ${props => props.marginBottom || '6px'};
  margin-left: ${props => props.marginLeft || '4px'};
  margin-right: ${props => props.marginRight || '4px'};
  background-color: ${props => props.bgColor || '#CCC'};
`;
