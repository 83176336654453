import React from 'react';
import { SiteLocations } from '@utils/consts';
import WideCoinBlock from '@components/coin-block/wide-block';
import { COIN_TYPE } from '@components/coin-block/defines';
import { ListContent } from './styles';

const GovernorateCurrencies = ({ data }) => {
  return (
    <ListContent>
      {data
        .filter(item => item.siteLocation === SiteLocations.GOVERNORATE_CURRENCIES)
        .map(item => (
          <WideCoinBlock
            key={item.id}
            title={item.title}
            siteLocation={SiteLocations.ARABIC_CURRENCIES}
            sell={item.sell}
            preSell={item.preSell}
            percentSell={item.percentSell}
            buy={item.buy}
            preBuy={item.preBuy}
            percentBuy={item.percentBuy}
            type={COIN_TYPE.BLACK}
            background={item.webImage}
          />
        ))}
    </ListContent>
  );
};

export default GovernorateCurrencies;