import React from 'react';
import { SiteLocations } from '@utils/consts';
import CoinBlock from '@components/coin-block';
import { COIN_TYPE } from '@components/coin-block/defines';
import { ListContent } from './styles';

const Materials = ({ data }) => {
  return (
    <ListContent>
      {data
        .filter(item => item.siteLocation === SiteLocations.MATERIALS)
        .map(item => (
          <CoinBlock
            key={item.id}
            title={item.title}
            value={item.sell}
            preValue={item.preSell}
            percentValue={item.percentSell}
            type={COIN_TYPE.BLACK}
          />
        ))}
    </ListContent>
  );
};

export default Materials;
