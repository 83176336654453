import React from 'react';
import { getStatusValue } from '@utils/helprs';
import { getArrowStatus } from './helper';
import {
  Box,
  Title,
  Top,
  Symbol,
  Value,
  PreValue,
  ChangePercent,
  BottomDetails,
  ArrowIcon,
} from './styles';

const CoinBlock = props => {
  const valueStatus = getStatusValue(props.preValue, props.value);

  return (
    <Box type={props.type}>
        <Top type={props.type}>
          <Title type={props.type}>{props.title}</Title>
          {!!props.symbol && <Symbol type={props.type}>{props.symbol}</Symbol>}
        </Top>
        <Value type={props.type}>{parseFloat(props.value)}</Value>
        <BottomDetails type={valueStatus}>
          <ChangePercent>{parseFloat(props.percentValue)}%</ChangePercent>
          <div className="side">
            <PreValue>{parseFloat(props.preValue)}</PreValue>
            <ArrowIcon>{getArrowStatus(valueStatus)}</ArrowIcon>
          </div>
        </BottomDetails>
    </Box>
  );
};

export default CoinBlock;
