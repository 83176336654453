import styled from 'styled-components';
import noImage from '@images/news-no-image.png';
import { Link } from 'gatsby';

export const Container = styled.div`
  padding: 60px 0 40px;
  background-color: ${props => props.theme.grayBackgroundColor};
`;

export const Title = styled.h1`
  color: ${props => props.theme.textColorBlack};
  text-align: center;
  font-family: ${props => props.theme.arabicFont};
  font-size: 30px;
  margin: 0 0 40px;

  :after {
    display: block;
    content: '';
    width: 70px;
    height: 3px;
    margin: 20px auto;
    background-color: ${props => props.theme.underlineColor};
  }
`;

export const NewsList = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  > * {
    margin: 15px 15px;
  }
`;

export const NewsImage = styled.div`
  width: 100px;
  height: 100px;
  background: ${props =>
    props.hasImage ? 'none' : `url(${noImage}) no-repeat center`};
  background-size: cover;
  border-radius: 4px;
  margin-left: 10px;

  img {
    max-width: 100%;
    object-fit: contain;
    height: 120px;
  }

  @media (min-width: 992px) {
    width: 185px;
    height: 120px;
    img {
      width: 185px;
    }
  }
`;

export const NewsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  transition: color 350ms ease;
  color: ${props => props.theme.textColorBlack};
  padding-bottom: 4px;
  text-align: right;

  @media (min-width: 992px) {
    text-align: right;
  }
`;

export const NewsTitle = styled.h2`
  font-family: ${props => props.theme.fonts.arabicFont};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

export const NewsDescription = styled.p`
  font-family: ${props => props.theme.fonts.arabicFont};
  font-size: 14px;
  margin: 0;
  line-height: 1.4;
`;

export const CreateDate = styled.h5`
  font-family: ${props => props.theme.fonts.englishFont};
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  margin: auto 0 0;
  display: flex;
  align-items: center;
  svg {
    margin-left: 4px;
  }
`;

export const NewsBlock = styled(Link)`
  background-color: ${props => props.theme.lightBackgroundColor};
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 14%);
  border-radius: 4px;
  width: 555px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 20px;
  transition: all 350ms ease;
  cursor: pointer;
  text-decoration: none;

  :hover {
    transform: translateY(-4px);
    background-color: ${props => props.theme.backgroundColor};

    ${NewsContent} {
      color: ${props => props.theme.textColor};
    }
  }

  @media (min-width: 992px) {
    grid-template-columns: auto 190px;
  }
`;

export const Paging = styled.div`
  margin: 0 30px;
`;
