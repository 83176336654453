import styled from 'styled-components';

export const TabsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 992px) {
    padding: 0 20px;
    margin: 40px 26px 0;
  }
`;

export const SelectContainer = styled.div`
  padding: 0 14px;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    display: none;
  }  

  .rrs .caret-icon {
    top: 22px;
  }
  .rrs__button {
    margin-top: 14px;
  }
  .rrs__label {
    border-radius: 0;
    font-family: ${props => props.theme.fonts.arabicFont};
    color: ${props => props.theme.textColorBlack};
    font-size: 18px;
    justify-content: center;
    width: ${typeof window !== 'undefined' ? `${window.innerWidth - 30}px` : `300px`};
  }
  .rrs--has-changed .rrs__label {
    color: ${props => props.theme.textColorBlack};
  }
  .rrs__options .rrs__option {
    font-family: ${props => props.theme.fonts.arabicFont};
    color: ${props => props.theme.textColorBlack};
    text-align: center;
    font-size: 18px;
    &.rrs__option--next-selection {
      color: ${props => props.theme.textColor};
      background: ${props => props.theme.backgroundColor};
    }
  }
`;

export const TabsNav = styled.div`
  background-color: ${props => props.theme.backgroundColor};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 26px;
  cursor: pointer;
  padding: 0 20px;
  border-radius: 4px;
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const TabItem = styled.div`
  color: ${props => props.theme.textColor};
  background-color: ${props =>
    props.selected ? props.theme.tabSelectedBg : 'transperant'};
  font-family: ${props => props.theme.fonts.arabicFont};
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0;
  padding: 11px 20px;
  min-width: 100px;
  text-align: center;
`;

export const TabContent = styled.div`
  position: relative;
  margin-bottom: 100px;
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  > * {
    margin: 15px 15px;
  }
`;
