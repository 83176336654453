import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Select, CaretIcon, ModalCloseButton } from 'react-responsive-select';
import { GET_CURRENCIES } from '@utils/queries';
import { TAB } from './consts';
import Dollar from './dollar';
import GovernorateCurrencies from './governorate-currencies';
import ArabicCurrencies from './arabic-currencies';
import ForeignCurrencies from './foreign-currencies';
import Gold from './gold';
import Materials from './materials';
import {
  TabContent,
  TabItem,
  TabsBox,
  TabsNav,
  SelectContainer,
} from './styles';
import 'react-responsive-select/dist/react-responsive-select.css';

const tabs = [
  'الحوالات',
  'المحافظات',
  'العملات العربية',
  'العملات الأجنبية',
  'الذهب',
  'بورصات',
];

const Tabs = () => {
  const { data } = useQuery(GET_CURRENCIES);
  const [selected, setSelected] = useState(0);

  return (
    <TabsBox>
      <SelectContainer>
        <Select
          name="current-list"
          modalCloseButton={<ModalCloseButton />}
          options={tabs.map((tab, index) => ({ value: index, text: tab }))}
          caretIcon={<CaretIcon />}
          selectedValue={selected}
          onChange={option => setSelected(option.value)}
        />
      </SelectContainer>

      <TabsNav>
        {tabs.map((item, index) => (
          <TabItem
            key={item}
            selected={index == selected}
            onClick={() => setSelected(index)}
          >
            {item}
          </TabItem>
        ))}
      </TabsNav>
      <TabContent>
        {TAB.TRANSFER == selected && <Dollar data={data.currencies} />}
        {TAB.GOVERNORATE_CURRENCIES == selected && (
          <GovernorateCurrencies data={data.currencies} />
        )}
        {TAB.ARABIC_CURRENCIES == selected && (
          <ArabicCurrencies data={data.currencies} />
        )}
        {TAB.FOREIGN_CURRENCIES == selected && (
          <ForeignCurrencies data={data.currencies} />
        )}
        {TAB.GOLD == selected && <Gold data={data.currencies} />}
        {TAB.MATERIALS == selected && <Materials data={data.currencies} />}
      </TabContent>
    </TabsBox>
  );
};

export default Tabs;
