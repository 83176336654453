import React from 'react';
import { getStatusValue } from '@utils/helprs';
import {
  FeaturedBox,
  Title,
  FeaturedContent,
  PreValue,
  ChangePercent,
  Value,
  BottomDetails,
  FeaturedDetails,
  ArrowIcon,
} from './styles';
import { getArrowStatus } from './helper';
import { COIN_TYPE_QUERY } from './defines';

const FeaturedBlock = props => {
  const oneColumn =
    props.type === COIN_TYPE_QUERY.GOLD ||
    props.type === COIN_TYPE_QUERY.MATERIAL;
  const buyStatus = oneColumn ? 0 : getStatusValue(props.preBuy, props.buy);
  const sellStatus = getStatusValue(props.preSell, props.sell);

  const details = oneColumn ? (
    <FeaturedContent>
      <Title>بيع</Title>
      <Value>{parseFloat(props.sell)}</Value>
      <BottomDetails type={sellStatus}>
        <div className="side">
          <PreValue>{parseFloat(props.preSell)}</PreValue>
          <ArrowIcon>{getArrowStatus(sellStatus)}</ArrowIcon>
        </div>
        <ChangePercent>{parseFloat(props.percentSell)}%</ChangePercent>
      </BottomDetails>
    </FeaturedContent>
  ) : (
    <>
      <FeaturedContent>
        <Title>شراء</Title>
        <Value>{parseFloat(props.buy)}</Value>
        <BottomDetails type={buyStatus}>
          <div className="side">
            <PreValue>{parseFloat(props.preBuy)}</PreValue>
            <ArrowIcon>{getArrowStatus(buyStatus)}</ArrowIcon>
          </div>
          <ChangePercent>{parseFloat(props.percentBuy)}%</ChangePercent>
        </BottomDetails>
      </FeaturedContent>
      <FeaturedContent>
        <Title>بيع</Title>
        <Value>{parseFloat(props.sell)}</Value>
        <BottomDetails type={sellStatus}>
          <div className="side">
            <PreValue>{parseFloat(props.preSell)}</PreValue>
            <ArrowIcon>{getArrowStatus(sellStatus)}</ArrowIcon>
          </div>
          <ChangePercent>{parseFloat(props.percentSell)}%</ChangePercent>
        </BottomDetails>
      </FeaturedContent>
    </>
  );

  return (
    <FeaturedBox hideBorder={props.hideBorder}>
      <Title>{props.title}</Title>
      <FeaturedDetails oneColumn={oneColumn}>{details}</FeaturedDetails>
    </FeaturedBox>
  );
};

export default FeaturedBlock;
