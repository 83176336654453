import React from 'react';
import { VALUE_STATUS } from './defines';
import { FiArrowUpCircle } from '@react-icons/all-files/fi/FiArrowUpCircle';
import { FiArrowDownCircle } from '@react-icons/all-files/fi/FiArrowDownCircle';
import { FiMinusCircle } from '@react-icons/all-files/fi/FiMinusCircle';

export const getArrowStatus = status => {
  if (status == VALUE_STATUS.DOWN) {
    return <FiArrowDownCircle />;
  } else if (status == VALUE_STATUS.UP) {
    return <FiArrowUpCircle />;
  }
  return <FiMinusCircle />;
};

export const formatWideBlockTitle = (title = '') =>
  title
    .trim()
    .split(/ |-|\//)
    .filter(text => text.length)
    .join('<br />');

export default { getArrowStatus };
