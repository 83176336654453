import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { FiClock } from '@react-icons/all-files/fi/FiClock';
import { GET_NEWS } from '@utils/queries';
// import { getSlug } from '@utils/helprs';
import { Button } from '@components/styledComponents/index';

import {
  Container,
  NewsBlock,
  NewsContent,
  NewsImage,
  NewsList,
  Title,
  NewsTitle,
  NewsDescription,
  CreateDate,
  Paging,
} from './styles';
const dayjs = require('dayjs');

const News = () => {
  const { data } = useQuery(GET_NEWS);
  const [showCount, setCount] = useState(10);
  const news = data ? data.news.slice(0, showCount) : [];

  return (
    <Container id="news">
      <Title>آخر الأخبار</Title>
      <NewsList>
        {news.map(item => {
          const date = dayjs(item.createdAt).format('YYYY-MM-DD hh:mm a');
          // /${encodeURIComponent(date)
          return (
            <NewsBlock
              key={item.id}
              to={`/news/${item.id}/`}
            >
              <NewsContent>
                <NewsTitle>{item.title}</NewsTitle>
                <NewsDescription>{item.content}</NewsDescription>
                <CreateDate>
                  {date} <FiClock />
                </CreateDate>
              </NewsContent>
              <NewsImage hasImage={!!item.image}>
                {item.image && (
                  <img src={`${process.env.CONTENT_URL}${item.image}`} />
                )}
              </NewsImage>
            </NewsBlock>
          );
        })}
      </NewsList>
      {showCount < data.news.length && (
        <Paging>
          <Button onClick={() => setCount(count => count + 10)}>المزيد</Button>
        </Paging>
      )}
    </Container>
  );
};

export default News;
