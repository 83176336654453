import React, { useContext, useEffect, useState } from 'react';
import FeaturedBlock from '@components/coin-block/featured-block';
import AppContext from '@utils/app-context';
import { SiteLocations } from '@utils/consts';

const Content = ({ data }) => {
  const {
    size: { width },
  } = useContext(AppContext);
  const [cellCount, setCellCount] = useState(1);

  useEffect(() => {
    if (width > 1809) {
      setCellCount(5);
    } else if (width > 1447) {
      setCellCount(4);
    } else if (width > 1085) {
      setCellCount(3);
    } else if (width > 723) {
      setCellCount(2);
    }
  }, [width]);

  return (
    <>
      {data.map((item, index) => {
        const position = index + 1;
        return (
          <FeaturedBlock
            key={item.id}
            title={item.title}
            siteLocation={SiteLocations.ARABIC_CURRENCIES}
            sell={item.sell}
            preSell={item.preSell}
            percentSell={item.percentSell}
            buy={item.buy}
            preBuy={item.preBuy}
            percentBuy={item.percentBuy}
            type={item.type}
            hideBorder={position % cellCount == 0 || position == data.length}
          />
        );
      })}
    </>
  );
};

export default Content;
