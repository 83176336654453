import React from 'react';
import { getStatusValue } from '@utils/helprs';
import { formatWideBlockTitle, getArrowStatus } from './helper';
import {
  Info,
  WideContent,
  WideBox,
  Title,
  Value,
  BottomDetails,
  ChangePercent,
  PreValue,
  ArrowIcon,
} from './styles';

const WideCoinBlock = props => {
  const buyStatus = getStatusValue(props.preBuy, props.buy);
  const sellStatus = getStatusValue(props.preSell, props.sell);

  return (
    <WideBox
      background={
        props.background
          ? `${process.env.CONTENT_URL}${props.background}`
          : null
      }
    >
      <Info>
        <Title
          dangerouslySetInnerHTML={{
            __html: formatWideBlockTitle(props.title),
          }}
        />
      </Info>
      <WideContent>
        <Title>شراء</Title>
        <Value>{parseFloat(props.buy)}</Value>
        <BottomDetails type={buyStatus}>
          <ChangePercent>{parseFloat(props.percentBuy)}%</ChangePercent>
          <div className="side">
            <PreValue>{parseFloat(props.preBuy)}</PreValue>
            <ArrowIcon>{getArrowStatus(buyStatus)}</ArrowIcon>
          </div>
        </BottomDetails>
      </WideContent>
      <WideContent>
        <Title>بيع</Title>
        <Value>{parseFloat(props.sell)}</Value>
        <BottomDetails type={sellStatus}>
          <ChangePercent>{parseFloat(props.percentSell)}%</ChangePercent>
          <div className="side">
            <PreValue>{parseFloat(props.preSell)}</PreValue>
            <ArrowIcon>{getArrowStatus(sellStatus)}</ArrowIcon>
          </div>
        </BottomDetails>
      </WideContent>
    </WideBox>
  );
};

export default WideCoinBlock;
